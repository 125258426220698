const applicationList = {
  localhost: {
    APP_NAME: 'latamfx',
    APP_TITLE: 'LatamFX',
  },
  'wluat-v2': {
    APP_NAME: 'latamfx',
    APP_TITLE: 'LatamFX',
  },
  uat: {
    APP_NAME: 'latamfx',
    APP_TITLE: 'LatamFX',
  },
  beta: {
    APP_NAME: 'latamfx',
    APP_TITLE: 'LatamFX',
  },
  bancolombia: {
    APP_NAME: 'bancolombia',
    APP_TITLE: 'Bancolombia',
  },
  makorfx: {
    APP_NAME: 'makor',
    APP_TITLE: 'Makor',
  },
  multivafx: {
    APP_NAME: 'multivafx',
    APP_TITLE: 'MultivaFX',
  },
  mextrader: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
    hasFavicon: true,
  },
  axiafx: {
    APP_NAME: 'axiafx',
    APP_TITLE: 'AxiaFX',
  },
  latamfx: {
    APP_NAME: 'latamfx',
    APP_TITLE: 'LatamFX',
  },
  sberbankfx: {
    APP_NAME: 'sberbankfx',
    APP_TITLE: 'SberBankFX',
  },
  edfmanfx: {
    APP_NAME: 'edfmanfx',
    APP_TITLE: 'Ed&fManFX',
  },
  xp: {
    APP_NAME: 'xp',
    APP_TITLE: 'Xp',
  },
  lloydsfx: {
    APP_NAME: 'lloydsfx',
    APP_TITLE: 'Lloyds Bank',
  },
  aliorfx: {
    APP_NAME: 'aliorfx',
    APP_TITLE: 'AliorFX',
  },
  tpifx: {
    APP_NAME: 'tpifx',
    APP_TITLE: 'TpiFX',
  },
  nologo: {
    APP_NAME: 'nologo',
    APP_TITLE: 'LatamFx',
  },
  '24exchangefx': {
    APP_NAME: '24exchangefx',
    APP_TITLE: '24ExchangeFX',
  },
  edgefxsg: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
  },
  edgefxuk: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
  },
  edgefxpro: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
  },
  edgefxeu: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
  },
  edgefx: {
    APP_NAME: 'edgefx',
    APP_TITLE: 'EdgeFX',
  },
  light: {
    APP_NAME: 'edgefxlight',
    APP_TITLE: 'EdgeFX',
  },
  gfifx: {
    APP_NAME: 'gfifx',
    APP_TITLE: 'GfiFX',
  },
  edgefxdemo: {
    APP_NAME: 'edgefxdemo',
    APP_TITLE: 'EdgeFX',
  },
  edgefxasia: {
    APP_NAME: 'edgefxasia',
    APP_TITLE: 'EdgeFXAsia',
  },
  edgefxlatam: {
    APP_NAME: 'edgefxlatam',
    APP_TITLE: 'EdgeFXLatam',
  },
  edgefxprime: {
    APP_NAME: 'edgefxprime',
    APP_TITLE: 'EdgeFXPrime',
  },
  mfdf: {
    APP_NAME: 'mfdf',
    APP_TITLE: 'Mifel',
    hasFavicon: true,
  },
  crypto: {
    APP_NAME: 'crypto',
    APP_TITLE: 'CPTrader',
    hasFavicon: true,
  },
  baes: {
    APP_NAME: 'baes',
    APP_TITLE: 'Banco Estado',
    hasFavicon: true,
  },
  base: {
    APP_NAME: 'base',
    APP_TITLE: 'Banco Base',
    hasFavicon: true,
  },
  banorte: {
    APP_NAME: 'banorte',
    APP_TITLE: 'BNTE',
    hasFavicon: true,
  },
  demobanorte: {
    APP_NAME: 'banorte',
    APP_TITLE: 'BNTE',
    hasFavicon: true,
  },
  uatbanorte: {
    APP_NAME: 'banorte',
    APP_TITLE: 'BNTE',
    hasFavicon: true,
  },
  banortedemo: {
    APP_NAME: 'banorte',
    APP_TITLE: 'BNTE',
    hasFavicon: true,
  },
  uatips: {
    APP_NAME: 'ipscorp',
    APP_TITLE: 'IPS Corp',
    APP_DEFAULT_LAYOUT: 'edgefx',
    hasFavicon: true,
  },
  demoips: {
    APP_NAME: 'ipscorp',
    APP_TITLE: 'IPS Corp',
    APP_DEFAULT_LAYOUT: 'edgefx',
    hasFavicon: true,
  },
  masari: {
    APP_NAME: 'msri',
    APP_TITLE: 'Masari',
  },
  trustone: {
    APP_NAME: 'trustone',
    APP_TITLE: 'Trust One',
  },
  bazteca: {
    APP_NAME: 'bazteca',
    APP_TITLE: 'Banco Azteca',
  },
  bcpfx: {
    APP_NAME: 'bcpfx',
    APP_TITLE: 'BCP',
  },
  cibanco: {
    APP_NAME: 'cibanco',
    APP_TITLE: 'CI Banco',
  },
  shinhan: {
    APP_NAME: 'shinhan',
    APP_TITLE: 'Shinhan',
  },
  banregio: {
    APP_NAME: 'banregio',
    APP_TITLE: 'Banregio',
    branding: 'edgefx', // we don't need to duplicate logo for the same branding
  },
  bancrea: {
    APP_NAME: 'bancrea',
    APP_TITLE: 'Bancrea',
  },
  demohb: {
    APP_NAME: 'hanabank',
    APP_TITLE: 'Hana Bank',
    APP_DEFAULT_LAYOUT: 'edgefx',
  },
  demokb: {
    APP_NAME: 'kb',
    APP_TITLE: 'kb',
    APP_DEFAULT_LAYOUT: 'edgefx',
  },
};

export default applicationList;
